import { Formik, Form, ErrorMessage, Field } from "formik";
import { useState } from "react";
import {
	forgotPassword as forgotPasswordFunction,
	submitPassword,
} from "../../../hooks/useAuth";
import { isValidPassword } from "../../../routes/auth/AuthForms";
import Swal from "sweetalert2";

interface VerifyCodeProps {
	setStateForms: any;
}

export default function PasswordForm({ setStateForms }: VerifyCodeProps) {
	//States
	const [forgotPassword, setForgotPassword] = useState(false);

	//Handlers
	const handlerForgotPassword = () => {
		setStateForms({
			forgotPassword: false,
		});
	};

	return (
		<div className="lg:w-1/2 container mx-auto xl:max-w-screen-sm">
			<div
				className="
							py-4
							bg-texture-parlans-logo
							lg:bg-white
							flex
							justify-center
						"
			>
				<div className="cursor-pointer flex items-center">
					<div className="container-logo">
						<img src="./img/PARLANS.png" alt="" />
					</div>
				</div>
			</div>
			<div
				className="
                        mt-10
                        px-12
                        sm:px-24
                        md:px-48
                        lg:px-12 lg:mt-8
                        xl:px-24 xl:max-w-2x 
                    "
			>
				<h2
					className="
                            text-center text-4xl text-black-900
                            font-display font-semibold
                            lg:text-center
                            xl:text-5xl xl:text-bold
                        "
				>
					Oops!
				</h2>
				<p className="text-center lg:text-center xl:text-1xl">
					Reset your password
				</p>
				<div className="mt-12">
					{forgotPassword ? (
						<Formik
							initialValues={{
								email: "",
								code: "",
								password: "",
								newPassword: "",
							}}
							validate={(values) => {
								const errors: any = {};
								if (!values.code) errors.code = "Required";
								if (!values.password)
									errors.password = "Required";
								if (!values.newPassword)
									errors.newPassword = "Required";
								return errors;
							}}
							onSubmit={async (values, { setSubmitting }) => {
								if (
									values.password !== values.newPassword ||
									!isValidPassword(values.newPassword)
								) {
									console.log(values);
									return Swal.fire({
										title: "Error",
										text: `Las contraseñas son incorrectas, recuerda que debe de tener 8 caracteres, un 
								caracter especial, un digito y al menos una letra mayuscula`,
										showConfirmButton: false,
										timer: 4000,
										icon: "info",
										heightAuto: false,
									});
								}

								const result = await submitPassword(
									values.email,
									values.code.toString(),
									values.newPassword
								);

								if (result.message) {
									return Swal.fire({
										title: "Error",
										text: result.message,
										showConfirmButton: false,
										timer: 2500,
										icon: "error",
										heightAuto: false,
									});
								}

								Swal.fire({
									title: "Password restart",
									text: `Contraseña restablecida con exito`,
									showConfirmButton: false,
									timer: 2500,
									icon: "success",
									heightAuto: false,
								});

								handlerForgotPassword();
								setSubmitting(false);
							}}
						>
							{({ isSubmitting }) => (
								<Form>
									<div>
										<div className="relative">
											<div
												className="
                                            inline-flex
                                            items-center
                                            justify-center
                                            absolute
                                            left-0
                                            top-0
                                            h-full
                                            w-10
                                            text-gray-400
                                        "
											>
												<img
													className="img-input"
													src="https://img.icons8.com/cute-clipart/64/000000/sms-token.png"
												/>
											</div>
											<Field
												id="code"
												type="number"
												name="code"
												className="
                                            shadow-md
                                            text-sm
                                            placeholder-gray-500
                                            pr-4
                                            rounded-2xl
                                            border border-blue-50
                                            pl-14
                                            w-full
                                            h-14
                                            py-2
                                            focus:outline-none focus:border-blue-400
                                            text-blue-500
                                        "
												placeholder="Code"
											/>
										</div>
										<ErrorMessage
											className="text-red-500 text-xs italic mt-2 flex justify-end"
											name="code"
											component="div"
										/>
									</div>
									<div>
										<div className="relative  mt-4">
											<div
												className="
                                            inline-flex
                                            items-center
                                            justify-center
                                            absolute
                                            left-0
                                            top-0
                                            h-full
                                            w-10
                                            text-gray-400
                                        "
											>
												<img
													className="img-input"
													src="https://img.icons8.com/cute-clipart/64/000000/lock.png"
												/>
											</div>
											<Field
												id="password"
												type="password"
												name="password"
												className="
                                            shadow-md
                                            text-sm
                                            placeholder-gray-500
                                            pr-4
                                            rounded-2xl
                                            border border-blue-50
                                            pl-14
                                            w-full
                                            h-14
                                            py-2
                                            focus:outline-none focus:border-blue-400
                                            text-blue-500
                                        "
												placeholder="Password"
											/>
										</div>
										<ErrorMessage
											className="text-red-500 text-xs italic mt-2 flex justify-end"
											name="password"
											component="div"
										/>
									</div>
									<div>
										<div className="relative mt-4">
											<div
												className="
                                            inline-flex
                                            items-center
                                            justify-center
                                            absolute
                                            left-0
                                            top-0
                                            h-full
                                            w-10
                                            text-gray-400
                                        "
											>
												<img
													className="img-input"
													src="https://img.icons8.com/cute-clipart/64/000000/lock.png"
												/>
											</div>
											<Field
												id="newPassword"
												type="password"
												name="newPassword"
												className="
                                            shadow-md
                                            text-sm
                                            placeholder-gray-500
                                            pr-4
                                            rounded-2xl
                                            border border-blue-50
                                            pl-14
                                            w-full
                                            h-14
                                            py-2
                                            focus:outline-none focus:border-blue-400
                                            text-blue-500
                                        "
												placeholder="Repeat Password"
											/>
										</div>
										<ErrorMessage
											className="text-red-500 text-xs italic mt-2 flex justify-end"
											name="newPassword"
											component="div"
										/>
									</div>

									<div className="mt-5 flex">
										<button
											type="submit"
											className="
                                            mr-5
											bg-gradient-parlans-blue
											text-gray-100
											p-2
											w-full
											rounded-full
											tracking-wide
											font-semibold font-display
											focus:outline-none
											focus:shadow-outline
											hover:bg-blue-400
											shadow-lg
											uppercase
											font-black
                                            text-xl
										"
											disabled={isSubmitting}
										>
											Reset Password
										</button>
										<button
											type="button"
											className="
                                        ml-5
											bg-gradient-parlans-blue
											text-gray-100
											p-2
											w-full
											rounded-full
											tracking-wide
											font-semibold font-display
											focus:outline-none
											focus:shadow-outline
											hover:bg-blue-400
											shadow-lg
											uppercase
											font-black
										"
											onClick={handlerForgotPassword}
										>
											Login
										</button>
									</div>
								</Form>
							)}
						</Formik>
					) : (
						<Formik
							initialValues={{ email: "" }}
							validate={(values) => {
								const errors: any = {};
								if (!values.email) errors.email = "Required";
								return errors;
							}}
							onSubmit={async (values, { setSubmitting }) => {
								const result: any =
									await forgotPasswordFunction(values.email);

								if (result.message) {
									return Swal.fire({
										icon: "error",
										showConfirmButton: false,
										heightAuto: false,
										text: result.message,
										timer: 2500,
									});
								}

								Swal.fire({
									icon: "info",
									showConfirmButton: false,
									heightAuto: false,
									text:
										"Se ha enviado un codigo al correo: " +
										values.email,
									timer: 2500,
								});

								setForgotPassword(true);
								setSubmitting(false);
							}}
						>
							{({ isSubmitting }) => (
								<Form>
									<div>
										<div className="relative">
											<div
												className="
                                            inline-flex
                                            items-center
                                            justify-center
                                            absolute
                                            left-0
                                            top-0
                                            h-full
                                            w-10
                                            text-gray-400
                                        "
											>
												<img
													className="img-input"
													src="https://img.icons8.com/cute-clipart/64/000000/email-sign.png"
												/>
											</div>
											<Field
												id="email"
												type="email"
												name="email"
												className="
                                            shadow-md
                                            text-sm
                                            placeholder-gray-500
                                            pr-4
                                            rounded-2xl
                                            border border-blue-50
                                            pl-14
                                            w-full
                                            h-14
                                            py-2
                                            focus:outline-none focus:border-blue-400
                                            text-blue-500
                                        "
												placeholder="E-mail"
											/>
										</div>
										<ErrorMessage
											className="text-red-500 text-xs italic mt-2 flex justify-end"
											name="email"
											component="div"
										/>
									</div>

									<div className="mt-5 flex">
										<button
											type="submit"
											className="
                                            mr-5
											bg-gradient-parlans-blue
											text-gray-100
											p-4
											w-full
											rounded-full
											tracking-wide
											font-semibold font-display
											focus:outline-none
											focus:shadow-outline
											hover:bg-blue-400
											shadow-lg
											uppercase
											font-black
										"
											disabled={isSubmitting}
										>
											Send Code
										</button>
										<button
											type="button"
											className="
                                        ml-5
											bg-gradient-parlans-blue
											text-gray-100
											p-4
											w-full
											rounded-full
											tracking-wide
											font-semibold font-display
											focus:outline-none
											focus:shadow-outline
											hover:bg-blue-400
											shadow-lg
											uppercase
											font-black
										"
											onClick={handlerForgotPassword}
										>
											Login
										</button>
									</div>
								</Form>
							)}
						</Formik>
					)}
				</div>
			</div>
		</div>
	);
}
