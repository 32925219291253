import AuthForms from "../auth/AuthForms";
// import "./sass/AuthScreen.scss";
const businessStill = require("../../assets/img/stills-business.png");

export default function AuthScreen(props: any) {
    console.log("Referer ", props.location.state?.from);
    return (
        <div className="flex justify-center items-center">
            <div className="lg:flex w-full">
                <AuthForms referrer={props.location.state?.from} />
                <div
                    className="
						hidden
						lg:flex
						items-center
						justify-center
						bg-texture-parlans
						flex-1
						h-screen
					"
                >
                </div>
            </div>
        </div>
    );
}
