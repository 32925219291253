import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Form, Formik } from "formik";
import { useToggle } from "rooks";
import { ApplicationConfig } from "../../application.config";
import useChat from "../../hooks/useChat";
import { useChatSender } from "../../hooks/useChatSender";
import MessageCard from "../cards/MessageCard";
import StickerMenu from "./StickerMenu"
import './sass/Chat.scss';

export default function Chat() {
    return (
        <div className="components-ui-chat chat">
            <ChatMessages roomId={ApplicationConfig.testMeeting} />
            <ChatBox roomId={ApplicationConfig.testMeeting} />
        </div>
    );
}

// Al separar en componentes diferentes, solamente se renderiza este cuando llega un mensaje.
function ChatMessages({ roomId }: { roomId: string }) {
    const { messages } = useChat(roomId);

    return (
        <div className="messages">
            {
                messages.map((msg) => (
                    <MessageCard
                        type={msg.type}
                        message={msg.message}
                        user={msg.user}
                        key={msg.messageId}
                    />
                ))
            }
        </div>
    );
}

function ChatBox({ roomId }: { roomId: string }) {
    const { sendMessage } = useChatSender(roomId);
    const [ showStickers, toggleStickerMenu ] = useToggle(true);

    return (
        <>
            <div className="message-input">
                <Formik
                    initialValues={{
                        message: ""
                    }}
                    onSubmit={async (values) => {
                        sendMessage(values.message, 'TEXT');
                        values.message = "";
                    }}
                >
                    <Form className="message-input">
                        <button
                            type="button"
                            onClick={toggleStickerMenu}
                        >
                            <FontAwesomeIcon icon="sticky-note" />
                        </button>
                        <Field name="message" type="text" placeholder="Escribe un mensaje..." />
                        <button type="submit">
                            <FontAwesomeIcon icon="paper-plane" />
                        </button>
                    </Form>
                </Formik>
            </div>
            <StickerMenu
                show={showStickers}
                // Es necesario pasar el handler, reutilizar el hook de useChat crea otra conexión.
                sendMessage={sendMessage}
            />
        </>
    );
}