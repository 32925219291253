import { useEffect } from "react";
import { useAttendeesList } from "../../hooks/useAttendeesList";
import './sass/Participants.scss';

export default function Participants() {
    const [map, subscribeToAttendees, unsubscribeToAttendees] = useAttendeesList();

    useEffect(
        () => {
            subscribeToAttendees();
            return () => unsubscribeToAttendees();
        },
        []
    )

    console.log(map, Object.keys(map), Object.values(map));
    const users = Object.values(map);
    return (
        // TODO: Separar los estilos de esto y pasarlos a la sidebar.
        <div className="components-ui-chat participants">
            {
                users.map((participant: any) => (
                    <div key={participant.id} className="participant">
                        <p>{participant.id}</p>
                    </div>
                ))
            }
        </div>
    );
}
