import { useApolloClient } from "@apollo/react-hooks";
import { Auth } from "aws-amplify";
import React, { useEffect, useMemo, useReducer} from "react";
import { useHistory } from "react-router-dom";

// Auth Reducer.
interface AuthState { isAuth: boolean; isLoading: boolean; }
interface AuthReducerAction { type: 'SIGN_IN' | 'SIGN_OUT' | 'LOADING'; }
interface AuthDispatchers { signIn: (values: any, referrer?: string) => Promise<any>; signOut: () => Promise<void>; }

export default function useAuth(): [AuthState, AuthDispatchers] {
    const client = useApolloClient();
    const history = useHistory();
    const [authState, dispatch] = useReducer(
        authReducer,
        {
            isLoading: true,
            isAuth: false
        }
    );

    const authDispatchers = useMemo(
        () => ({
            signIn: async (values: any, referrer?: string) => {
                try {
                    await Auth.signIn(values.email, values.password);
                    dispatch({ type: 'SIGN_IN' });
                    if (referrer) history.replace(referrer);
                } catch (error) {
                    return error;
                }
                 
            },
            signOut: async () => {
                dispatch({ type: 'LOADING' });
                await client.cache.reset();
                await Auth.signOut();
                dispatch({ type: 'SIGN_OUT' });
            }
        }),
        []
    );


    useEffect(() => {
        const verifyToken = async () => {
            try {
                const credentials = await Auth.currentSession();
                console.log(credentials);
                dispatch({ type: 'SIGN_IN' })
            } catch (err) {
                console.error(err);
                dispatch({ type: 'SIGN_OUT' })
            }
        }

        verifyToken();
    }, []);

    return [authState, authDispatchers];
}

// Auth Functions.
export const signUp = async (values: any) => {
    try {
        await Auth.signUp({
            username: values.email,
            password: values.password,
            // De esta forma le pasamos info adicional a nuestra lambda para registrarla en DynamoDB.
            clientMetadata: {
                name: values.name,
                lastname: values.lastname
            },
            attributes: {
                email: values.email
            }
        });

        return { success: true }
    } catch (error) {
        return error
    }
        
}

export const verifyCode = async (email: string, code: string) => {
    try {
        await Auth.confirmSignUp(email, code);
        return { success: true }

    } catch (error) {
        return {
            error: true,
            message: error
        }
    }
}

export const sendCode = async (email: string) => {
    try {
        await Auth.resendSignUp(email);
        return {
            error: false,
            message: "Codigo reenviado"
        };
    } catch (err) {
        return {
            error: true,
            message: err
        };
    }
}

export const forgotPassword = async (email: string) => {
    return Auth.forgotPassword(email)
    .then(data => {return data})
    .catch(err => {return err});
}

export const submitPassword = async (email: string, code: string, newPassword: string) => {
    return Auth.forgotPasswordSubmit(email, code, newPassword)
    .then(data => {return data})
    .catch(err => {return err});
}

const authReducer: React.Reducer<AuthState, AuthReducerAction> = (prevState, action) => {
    switch (action.type) {
        case 'SIGN_IN':
            return {
                isAuth: true,
                isLoading: false
            }
        case 'SIGN_OUT':
            return {
                isAuth: false,
                isLoading: false
            }
        case 'LOADING':
            return {
                ...prevState,
                isLoading: true
            }
        default:
            return prevState;
    }
}