import { Formik, Form, ErrorMessage, Field } from "formik";
import Swal from "sweetalert2";
import { isValidPassword } from "../../../routes/auth/AuthForms";
import "./css/Signin.scss";

interface MainFormProps {
	setStateForms: any;
	context: any;
	referrer?: string;
	toggleMode: () => void;
	setData: any;
}

export default function MainForm({
	setStateForms,
	context,
	referrer,
	toggleMode,
	setData,
}: MainFormProps) {
	//handlers
	const handlerForgotPassword = (e: any) => {
		setStateForms({
			forgotPassword: true,
		});
	};

	return (
		<Formik
			initialValues={{ email: "", password: "" }}
			validate={(values) => {
				const errors: any = {};
				if (!values.email) errors.email = "Required";
				if (!values.password) errors.password = "Required";
				return errors;
			}}
			onSubmit={async (values, { setSubmitting }) => {
				if (!isValidPassword(values.password))
					return Swal.fire({
						title: "Error",
						text: "Password incorrect",
						icon: "error",
						heightAuto: false,
						showConfirmButton: false,
						timer: 2000,
					});

				const result = await context.signIn(values, referrer);

				//Sino esta verificado muestra el de verificar codigo
				if (result.code === "UserNotConfirmedException") {
					setData({
						email: values.email,
					});
					setStateForms({
						verificationCode: true,
					});
				} else {
					Swal.fire({
						icon: "error",
						showConfirmButton: false,
						heightAuto: false,
						text: result.message,
						timer: 2500,
					});
				}

				setSubmitting(false);
			}}
		>
			{({ isSubmitting }) => (
				<div className="lg:w-1/2 container mx-auto xl:max-w-screen-sm">
					<div
						className="
							py-4
							bg-texture-parlans-logo
							lg:bg-white
							flex
							justify-center
						"
					>
						<div className="cursor-pointer flex items-center">
							<div className="container-logo">
								<img src="./img/PARLANS.png" alt="" />
							</div>
						</div>
					</div>
					<div
						className="
                        mt-10
                        px-12
                        sm:px-24
                        md:px-48
                        lg:px-12 lg:mt-8
                        xl:px-24 xl:max-w-2x 
                    "
					>
						<h2
							className="
                            text-center text-4xl text-black-900
                            font-display font-semibold
                            lg:text-center
                            xl:text-5xl xl:text-bold
                        "
						>
							Hello!
						</h2>
						<p className="text-center lg:text-center xl:text-1xl">
							Sign into your account
						</p>
						<div className="mt-12">
							<Form>
								<div>
									<div className="relative">
										<div
											className="
                                            inline-flex
                                            items-center
                                            justify-center
                                            absolute
                                            left-0
                                            top-0
                                            h-full
                                            w-10
                                            text-gray-400
                                        "
										>
											<img
												className="img-input"
												src="https://img.icons8.com/cute-clipart/64/000000/email-sign.png"
											/>
										</div>
										<Field
											id="email"
											type="email"
											name="email"
											className="
                                            shadow-md
                                            text-sm
                                            placeholder-gray-500
                                            pr-4
                                            rounded-2xl
                                            border border-blue-50
                                            pl-14
                                            w-full
                                            h-14
                                            py-2
                                            focus:outline-none focus:border-blue-400
                                            text-blue-500
                                        "
											placeholder="E-mail"
										/>
									</div>
									<ErrorMessage
										className="text-red-500 text-xs italic mt-2 flex justify-end"
										name="email"
										component="div"
									/>
								</div>

								<div className="relative mt-8">
									<div
										className="
												inline-flex
												items-center
												justify-center
												absolute
												left-0
												top-0
												h-full
												w-10
												text-gray-400
											"
									>
										<img
											className="img-input"
											src="https://img.icons8.com/cute-clipart/64/000000/lock.png"
										/>
									</div>
									<Field
										className="
												shadow-md
												text-sm
												placeholder-gray-500
												pr-4
												rounded-2xl
												border border-blue-50
												pl-14
												w-full
												h-14
												py-2
												focus:outline-none
												focus:border-blue-400
												text-blue-500
											"
										type="password"
										name="password"
										placeholder="Password"
									/>
								</div>
								<ErrorMessage
									className="text-red-500 text-xs italic mt-2 flex justify-end"
									name="password"
									component="div"
								/>
								<div
									className="
											flex
											justify-between
											items-center
											mt-2
										"
								>
									<div
										className="
												text-xs
												font-display font-semibold
												text-blue-400
												hover:text-indigo-800
											"
									>
										<Field
											className="cursor-pointer"
											type="checkbox"
											name="Remember"
											id="Remember"
										/>
										<label
											className="cursor-pointer"
											htmlFor="Remember"
										>
											&nbsp; Remember me
										</label>
									</div>
									<button
										className="
												text-xs
												font-display font-semibold
												text-blue-400
												hover:text-indigo-800
												cursor-pointer
											"
										onClick={handlerForgotPassword}
										type="button"
									>
										Forgot password?
									</button>
								</div>
								<div className="mt-10">
									<button
										type="submit"
										className="
											bg-gradient-parlans-blue
											text-gray-100
											p-4
											w-full
											rounded-full
											tracking-wide
											font-semibold font-display
											focus:outline-none
											focus:shadow-outline
											hover:bg-blue-400
											shadow-lg
											uppercase
											font-black
										"
										disabled={isSubmitting}
									>
										Sign In
									</button>
								</div>
							</Form>
							<div
								className="
									mt-12
									text-sm
									font-display font-semibold
									text-blue-300 text-center
								"
							>
								Don't have an account?
								<a
									onClick={toggleMode}
									className="
										cursor-pointer
										text-blue-400
										hover:text-indigo-800
										ml-1
									"
								>
									Create One
								</a>
							</div>
						</div>
					</div>
				</div>
			)}
		</Formik>
	);
}
