import LottieElement from "./LottieElement";
import "./sass/MessageCard.scss";

export default function MessageCard(props: MessageCardProps) {
	function messageContent() {
		switch (props.type) {
			case "TEXT":
				return <p>{props.message}</p>;
			case "STICKER":
				return (
					<div className="sticker">
						<img src={props.message} alt="" />
					</div>
				);
			case "ANIMATED":
				return (
					<div className="sticker">
						<LottieElement path={props.message} autoplay={true} />
					</div>
				);
			default:
				console.log(
					`Tipo de mensaje no reconocido! (${props.type}, ${props.message})`
				);
		}
	}

	return (
		<>
			{
				// Para prevenir mensajes vacío.
				props.message.trim() !== "" ? (
					<div className="parlans-message">
						<p className="parlans-message-user">{props.user}</p>
						{messageContent()}
					</div>
				) : (
					<></>
				)
			}
		</>
	);
}

interface MessageCardProps {
	user: string;
	message: string;
	type: string;
	key: string;
}
