import { Formik, Form, Field, ErrorMessage } from "formik";
import { signUp } from "../../hooks/useAuth";
import { isValidPassword } from "../../routes/auth/AuthForms";
import Swal from "sweetalert2";
import "./SignIn/css/Signin.scss";

interface AuthChildFormProps {
	toggleMode: () => void;
}

export default function SignUp({ toggleMode }: AuthChildFormProps) {
	return (
		<Formik
			initialValues={{ name: "", lastname: "", email: "", password: "" }}
			validate={(values) => {
				const errors: any = {};
				if (!values.name) errors.name = "Required";
				if (!values.lastname) errors.lastname = "Required";
				if (!values.email) errors.email = "Required";
				if (!values.password) errors.password = "Required";
				return errors;
			}}
			onSubmit={async (values, { setSubmitting }) => {
				if (!isValidPassword(values.password)) {
					return Swal.fire({
						title: "Error",
						text: "Password must be at least 8 characters long, contain at least one number, one character special and one word uppercase",
						icon: "error",
						heightAuto: false,
						showConfirmButton: false,
						timer: 5000,
					});
				}
				const result: any = await signUp(values);

				if (!result.success) {
					return Swal.fire({
						icon: "error",
						title: "Error de registro!",
						text: result.message,
						showConfirmButton: false,
						heightAuto: false,
						timer: 2500,
						timerProgressBar: true,
						allowOutsideClick: false,
					});
				}

				Swal.fire({
					icon: "success",
					title: "Cuenta creada con exito",
					text: `Se ha enviado un código de verificación a ${values.email}`,
					showConfirmButton: false,
					heightAuto: false,
					timer: 2500,
					timerProgressBar: true,
					allowOutsideClick: false,
				});

				toggleMode();
				setSubmitting(false);
			}}
		>
			{({ isSubmitting }) => (
				<div className="lg:w-1/2 container mx-auto xl:max-w-screen-sm">
					<div
						className="
                        py-4
                        bg-texture-parlans-logo
                        lg:bg-white
                        flex
                        justify-center
                    "
					>
						<div className="cursor-pointer flex items-center">
							<div className="container-logo">
								<img src="../img/PARLANS.png" alt="" />
							</div>
						</div>
					</div>
					<div
						className="
                        mt-10
                        px-5
                        sm:px-24
                        md:px-48
                        lg:px-12 lg:mt-8
                        xl:px-24 xl:max-w-2xl
                    "
					>
						<h2
							className="
                            text-center text-4xl text-black-900
                            font-display font-semibold
                            lg:text-center
                            xl:text-5xl xl:text-bold
                        "
						>
							Hello, friend
						</h2>
						<div className="mt-12">
							<Form>
								<div className="relative">
									<div
										className="
												inline-flex
												items-center
												justify-center
												absolute
												left-0
												top-0
												h-full
												w-10
												text-gray-400
											"
									>
										<img
											className="img-input"
											src="https://img.icons8.com/cute-clipart/64/000000/user-male-circle.png"
										/>
									</div>

									<Field
										id="name"
										type="text"
										name="name"
										placeholder="Name"
										className="
												shadow-md
												text-sm
												placeholder-gray-500
												pr-4
												rounded-2xl
												border border-blue-50
												pl-14
												w-full
												h-14
												py-2
												focus:outline-none
												focus:border-blue-400
												text-blue-500
											"
									/>
								</div>
								<ErrorMessage
									className="text-red-500 text-xs italic mt-2 flex justify-end"
									name="name"
									component="div"
								/>

								<div className="relative mt-2">
									<div
										className="
												inline-flex
												items-center
												justify-center
												absolute
												left-0
												top-0
												h-full
												w-10
												text-gray-400
											"
									>
										<img
											className="img-input"
											src="https://img.icons8.com/cute-clipart/64/000000/tallo.png"
										/>
									</div>

									<Field
										id="lastname"
										type="text"
										name="lastname"
										placeholder="Last Name"
										className="
												shadow-md
												text-sm
												placeholder-gray-500
												pr-4
												rounded-2xl
												border border-blue-50
												pl-14
												w-full
												h-14
												py-2
												focus:outline-none
												focus:border-blue-400
												text-blue-500
											"
									/>
								</div>
								<ErrorMessage
									className="text-red-500 text-xs italic mt-2 flex justify-end"
									name="lastname"
									component="div"
								/>

								<div className="relative mt-2">
									<div
										className="
											inline-flex
											items-center
											justify-center
											absolute
											left-0
											top-0
											h-full
											w-10
											text-gray-400
										"
									>
										<img
											className="img-input"
											src="https://img.icons8.com/cute-clipart/64/000000/email-sign.png"
										/>
									</div>

									<Field
										id="email"
										type="email"
										name="email"
										placeholder="E-mail"
										className="
											shadow-md
											text-sm
											placeholder-gray-500
											pr-4
											rounded-2xl
											border border-blue-50
											pl-14
											w-full
											h-14
											py-2
											focus:outline-none
											focus:border-blue-400
											text-blue-500
										"
									/>
								</div>
								<ErrorMessage
									className="text-red-500 text-xs italic mt-2 flex justify-end"
									name="email"
									component="div"
								/>

								<div className="relative mt-2">
									<div
										className="
												inline-flex
												items-center
												justify-center
												absolute
												left-0
												top-0
												h-full
												w-10
												text-gray-400
											"
									>
										<img
											className="img-input"
											src="https://img.icons8.com/cute-clipart/64/000000/lock.png"
										/>
									</div>

									<Field
										id="password"
										type="password"
										name="password"
										className="
												shadow-md
												text-sm
												placeholder-gray-500
												pr-4
												rounded-2xl
												border border-blue-50
												pl-14
												w-full
												h-14
												py-2
												focus:outline-none
												focus:border-blue-400
												text-blue-500
											"
										placeholder="Password"
									/>
								</div>
								<ErrorMessage
									className="text-red-500 text-xs italic mt-2 flex justify-end"
									name="password"
									component="div"
								/>

								<div
									className="
										flex
										justify-center
										items-center
										mt-5
									"
								>
									<div
										className="
											text-xs
											font-display font-semibold
											text-blue-400
											hover:text-indigo-800
										"
									>
										<input
											type="checkbox"
											name="Remember"
											id=""
										/>
										I read and agree to
									</div>
									<a
										className="
											text-xs
											font-display font-semibold
											text-blue-400
											hover:text-indigo-800
											cursor-pointer
											ml-1
										"
									>
										Terms & Conditions
									</a>
								</div>
								<div className="mt-10">
									<button
										disabled={isSubmitting}
										type="submit"
										className="
											bg-gradient-parlans-blue
											text-gray-100
											p-4
											w-full
											rounded-full
											tracking-wide
											font-semibold font-display
											focus:outline-none
											focus:shadow-outline
											hover:bg-blue-400
											shadow-lg
											uppercase
											font-black
										"
									>
										Create Account
									</button>
								</div>
							</Form>
							<div
								className="
									mt-5
									text-sm
									font-display font-semibold
									text-blue-300 text-center
								"
							>
								Already have an account?
								<a
									onClick={toggleMode}
									className="
										cursor-pointer
										text-blue-400
										hover:text-indigo-800
										ml-1
									"
								>
									Sign In
								</a>
							</div>
						</div>
					</div>
				</div>
			)}
		</Formik>
	);
}
