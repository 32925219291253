export const ApplicationConfig = {
    endpoint: 'http://localhost:3000/dev',
    // Dev
    // awsConfig: {
    //     Auth: {
    //         userPoolId: 'us-east-2_nb0GdgSUT',
    //         region: 'us-east-2',
    //         userPoolWebClientId: '768055gp8mlrop80kqvdoltiju'
    //     },
    //     Api: {
    //         region: 'us-east-2',
    //         graphqlEndpoint: 'https://5h75o4pqgjezfng4ej7qbikjjq.appsync-api.us-east-2.amazonaws.com/graphql',
    //         authType: 'AMAZON_COGNITO_USER_POOLS'
    //     }
    // },
    // testMeeting: 'dev:ae2fe61d-a0f2-4e47-9559-56ac3575b619',
    // QA
    testMeeting: 'qa:94adf2fd-f63c-42d5-80a5-e71e6cb2eaf7',
    awsConfig: {
        Auth: {
            userPoolId: 'us-west-2_DYPGXT1c7',
            region: 'us-west-2',
            userPoolWebClientId: '5dogp3onfm0jf51tq5550qukf9'
        },
        Api: {
            region: 'us-west-2',
            graphqlEndpoint: 'https://ueikjniq35ddnj753uieyukvae.appsync-api.us-west-2.amazonaws.com/graphql',
            authType: 'AMAZON_COGNITO_USER_POOLS'
        }
    },
    ivsLib: 'https://player.live-video.net/1.4.0/amazon-ivs-player.min.js'
};