import { gql, useApolloClient } from "@apollo/react-hooks";
import { 
    AudioInputControl,
    ContentShareControl,
    useMeetingManager,
    VideoInputControl,
    VideoTileGrid,
    ControlBarButton,
    Phone
} from "amazon-chime-sdk-component-library-react";
import { useEffect, useState } from "react";
import { EmptyMeetingScreen } from "./EmptyMeetingScreen";
import { VolumeButton } from "./VolumeButton";
import './sass/Meeting.scss';
import { ApplicationConfig } from "../../application.config";

export function Meeting() {
    const [status, setStatus] = useState('LOADING');
    const apolloClient = useApolloClient();
    const meetingManager = useMeetingManager();

    const loadMeeting = async () => {
        try {
            setStatus('LOADING');
            const result = await apolloClient.mutate({
                mutation: JOIN_MEETING,
                variables: {
                    meetingId: ApplicationConfig.testMeeting
                }
            });

            const { data } = result;
            console.log(data);
            await meetingManager.join({
                attendeeInfo: {
                    AttendeeId: data.joinMeeting.attendee.attendeeId,
                    ExternalUserId: data.joinMeeting.attendee.userId,
                    JoinToken: data.joinMeeting.attendee.connectionToken,
                },
                meetingInfo: {
                    ExternalMeetingId: ApplicationConfig.testMeeting,
                    MediaPlacement: JSON.parse(data.joinMeeting.meeting.connectionData.MediaPlacement),
                    MediaRegion: data.joinMeeting.meeting.connectionData.MediaRegion,
                    MeetingId: result.data.joinMeeting.meeting.meetingId,
                },
            });

            meetingManager.start();
            setStatus('JOINED');
        } catch (error) {
            console.error(error);
            setStatus('INACTIVE');
        }
    }

    useEffect(
        () => {
            loadMeeting();
            return () => {
                meetingManager.leave();
            }
        },
        []
    );

    const endMeeting = async () => {
        setStatus('LOADING');
        await meetingManager.leave();
        setStatus('HANG_UP');
    }

    return (
        <div className="components-meetings-meeting">
            <div className="video">
                <VideoTileGrid 
                    className="video-tile"
                    noRemoteVideoView={
                        <EmptyMeetingScreen
                            status={status as any}
                            retryHandler={loadMeeting}
                        />                      
                    }
                >
                </VideoTileGrid>
            </div>
            <div className="controls">
                {
                    status === 'JOINED' ? (
                        <>
                            <AudioInputControl />
                            <ContentShareControl />
                            <VideoInputControl />
                            <VolumeButton />
                            {/* End button */}
                            <ControlBarButton
                                icon={<Phone />}
                                label="End"
                                onClick={endMeeting}
                            />
                        </>
                    ) : null
                }
            </div>
        </div>
    );
}

const JOIN_MEETING = gql`
    mutation joinMeeting($meetingId: ID!) {
        joinMeeting(meetingId: $meetingId) {
            meeting {
                meetingId
                serviceMeetingId
                connectionData {
                    MediaPlacement
                    MediaRegion
                }
            }
            attendee {
                attendeeId
                userId
                connectionToken
            }
        }
    }
`;