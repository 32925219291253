import { MeetingStatus, useMeetingStatus } from "amazon-chime-sdk-component-library-react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Chat from "./Chat";
import Participants from "./Participants";
import Poll from "./Poll";
import './sass/Sidebar.scss';

export default function Sidebar() {
	const meetingStatus = useMeetingStatus();
    
	if (meetingStatus !== MeetingStatus.Succeeded) return null;

	return (
		<div className="component-ui-sidebar">
			<Tabs
				className="sidebar-tabs"
				defaultIndex={0}
				selectedTabPanelClassName="selected-panel"
				selectedTabClassName="selected-tab"
				forceRenderTabPanel={true}
			>
				<TabList>
					<Tab value={0}>Chat</Tab>
					<Tab value={1}>Participants</Tab>
					<Tab value={2}>Polls</Tab>
				</TabList>
				<TabPanel className="sidebar-tab-panel">
					<Chat />
				</TabPanel>
				<TabPanel className="sidebar-tab-panel">
					<Participants />
				</TabPanel>
				<TabPanel className="sidebar-tab-panel">
					<Poll />
				</TabPanel>
			</Tabs>
		</div>
	);
}