import { Formik, Field, ErrorMessage, Form } from "formik";
import { sendCode, verifyCode } from "../../../hooks/useAuth";
import Swal from "sweetalert2";

interface VerifyCodeProps {
	email: string;
	setStateForms: any;
}

export default function VerifyCode({ email, setStateForms }: VerifyCodeProps) {
	//Handlers
	const handlerSendCode = async () => {
		const result: any = await sendCode(email);

		if (result?.error) {
			Swal.fire({
				icon: "error",
				showConfirmButton: false,
				heightAuto: false,
				text: result.message,
				timer: 2500,
			});
		} else {
			Swal.fire({
				icon: "success",
				showConfirmButton: false,
				heightAuto: false,
				text: result.message,
				timer: 2500,
			});
		}
	};

	const handlerVerifyCode = async (code: string) => {
		const result: any = await verifyCode(email, code);

		if (result?.error) {
			Swal.fire({
				icon: "error",
				showConfirmButton: false,
				heightAuto: false,
				text: result.message,
				timer: 2500,
			});
		} else {
			Swal.fire({
				icon: "success",
				showConfirmButton: false,
				heightAuto: false,
				text: "Cuenta verificada con exito, vuelva a iniciar sesion",
				timer: 2500,
			});

			setStateForms({
				verificationCode: false,
			});
		}
	};

	return (
		<div className="lg:w-1/2 container mx-auto xl:max-w-screen-sm">
			<div
				className="
							py-4
							bg-texture-parlans-logo
							lg:bg-white
							flex
							justify-center
						"
			>
				<div className="cursor-pointer flex items-center">
					<div className="container-logo">
						<img src="./img/PARLANS.png" alt="" />
					</div>
				</div>
			</div>
			<div
				className="
                        mt-10
                        px-12
                        sm:px-24
                        md:px-48
                        lg:px-12 lg:mt-8
                        xl:px-24 xl:max-w-2x 
                    "
			>
				<h2
					className="
                            text-center text-4xl text-black-900
                            font-display font-semibold
                            lg:text-center
                            xl:text-5xl xl:text-bold
                        "
				>
					Waiting
				</h2>
				<p className="text-center lg:text-center xl:text-1xl">
					Verification Code
				</p>
				<div className="mt-12">
					<Formik
						initialValues={{ code: "" }}
						validate={(values) => {
							const errors: any = {};
							if (!values.code) errors.code = "Required";
							return errors;
						}}
						onSubmit={async (values, { setSubmitting }) => {
							await handlerVerifyCode(values.code);
							setSubmitting(false);
						}}
					>
						{({ isSubmitting }) => (
							<Form>
								<div>
									<div className="relative">
										<div
											className="
                                            inline-flex
                                            items-center
                                            justify-center
                                            absolute
                                            left-0
                                            top-0
                                            h-full
                                            w-10
                                            text-gray-400
                                        "
										>
											<img
												className="img-input"
												src="https://img.icons8.com/cute-clipart/64/000000/sms-token.png"
											/>
										</div>
										<Field
											id="code"
											type="text"
											name="code"
											className="
                                            shadow-md
                                            text-sm
                                            placeholder-gray-500
                                            pr-4
                                            rounded-2xl
                                            border border-blue-50
                                            pl-14
                                            w-full
                                            h-14
                                            py-2
                                            focus:outline-none focus:border-blue-400
                                            text-blue-500
                                        "
											placeholder="Code"
										/>
									</div>
									<ErrorMessage
										className="text-red-500 text-xs italic mt-2 flex justify-end"
										name="code"
										component="div"
									/>
								</div>
								<div className="mt-5 flex">
									<button
										type="submit"
										className="
                                            mr-5
											bg-gradient-parlans-blue
											text-gray-100
											p-4
											w-full
											rounded-full
											tracking-wide
											font-semibold font-display
											focus:outline-none
											focus:shadow-outline
											hover:bg-blue-400
											shadow-lg
											uppercase
											font-black
										"
										disabled={isSubmitting}
									>
										Verify
									</button>
									<button
										type="submit"
										className="
                                        ml-5
											bg-gradient-parlans-blue
											text-gray-100
											p-4
											w-full
											rounded-full
											tracking-wide
											font-semibold font-display
											focus:outline-none
											focus:shadow-outline
											hover:bg-blue-400
											shadow-lg
											uppercase
											font-black
										"
										onClick={handlerSendCode}
									>
										Send
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}
