import { Fragment, useContext, useState } from 'react'
import AuthContext from '../../contexts/AuthContext';
import MainForm from './SignIn/MainForm'
import VerifyCode from './SignIn/VerifyCodeForm'
import ForgotPasswordForm from './SignIn/ForgotPasswordForm'

interface AuthChildFormProps { toggleMode: () => void, referrer?: string }

export default function SignIn({ toggleMode, referrer }: AuthChildFormProps) {
    const context: any = useContext(AuthContext);

    //state
    const [stateForms, setStateForms] = useState({
        forgotPassword: false,
        verificationCode: false
    });

    const [data, setData] = useState({
        email: ''
    });

    //handlers

    return (
        <Fragment>
            {
                stateForms.verificationCode ? (
                // false ? (
                    <VerifyCode
                        email={data.email}
                        setStateForms = {setStateForms} />
                ) : (
                    stateForms.forgotPassword ? (
                        <ForgotPasswordForm 
                        setStateForms = {setStateForms}/>
                    ) : (
                        <MainForm
                            setStateForms={setStateForms}
                            context={context}
                            referrer={referrer}
                            toggleMode={toggleMode}
                            setData={setData} />
                    )
                )
            }
        </Fragment>

    );

}