import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useCallback } from "react";
import { useMapState } from "rooks";

export interface Attendee {
    id: string;
    // Proximamente otros campos.
}

export const useAttendeesList = () => {
    const meetingManager = useMeetingManager();
    const [map, { set, has, remove, removeAll }] = useMapState({});
    const callback = useCallback(
        (attendeeId: string, present: boolean, externalId: string) => {
            console.log(`Attendee ID: ${attendeeId} Present: ${present} Parlans id: ${externalId}`);
            // TODO: Se pueden traer los datos desde la API.
            if (present) {
                // Checamos que no exista el usuario ya.
                if (has(externalId)) return;
                return set(externalId, { id: externalId });
            }
            // TODO: Disparar evento de nuevo usuario.
            remove(externalId);
        },
        []
    );

    const subscribeToAttendees = () => {
        meetingManager.audioVideo?.realtimeSubscribeToAttendeeIdPresence(callback as any);
    }

    const unsubscribeToAttendees = () => {
        removeAll();
        meetingManager.audioVideo?.realtimeUnsubscribeToAttendeeIdPresence(callback as any);
    }

    return [ map, subscribeToAttendees, unsubscribeToAttendees ];
}